import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx, createSlots as _createSlots, createVNode as _createVNode, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0b975dcc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "table-bg" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_Edit = _resolveComponent("Edit")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_Delete = _resolveComponent("Delete")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_el_pagination = _resolveComponent("el-pagination")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.hideSpace?'':'table-header')
    }, [
      _renderSlot(_ctx.$slots, "header", {}, undefined, true)
    ], 2),
    _withDirectives((_openBlock(), _createBlock(_component_el_table, {
      height: _ctx.height,
      "cell-style": _ctx.rowStyle,
      "row-style": _ctx.rowStyle,
      "header-cell-style": _ctx.headerRowStyle,
      ref: "table",
      data: _ctx.dataList,
      border: _ctx.border,
      "row-key": _ctx.rowKey,
      "scrollbar-always-on": _ctx.scrollbarAlwaysOn,
      "default-expand-all": "",
      "highlight-current-row": _ctx.highLightRow,
      onCurrentChange: _ctx.handleCurrentRowChange,
      onSelectionChange: _ctx.handleSelectionChange,
      onRowClick: _ctx.rowClick
    }, {
      default: _withCtx(() => [
        (_ctx.showCheckbox)
          ? (_openBlock(), _createBlock(_component_el_table_column, {
              key: 0,
              type: "selection",
              width: "50",
              align: "center"
            }))
          : _createCommentVNode("", true),
        (_ctx.showIndex)
          ? (_openBlock(), _createBlock(_component_el_table_column, {
              key: 1,
              type: "index",
              label: "序号",
              align: "center",
              width: "50"
            }))
          : _createCommentVNode("", true),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.props, (k, i) => {
          return (_openBlock(), _createBlock(_component_el_table_column, {
            key: k,
            prop: k,
            label: _ctx.columnTitles[i] == '' ? k : _ctx.columnTitles[i],
            align: _ctx.customColumnNotAlign.includes(k)?'':'center',
            "min-width": Object.keys(_ctx.customColumnWidth).includes(k)? _ctx.customColumnWidth[k]: 120,
            "show-overflow-tooltip": true,
            formatter: _ctx.formater,
            sortable: _ctx.sortColumns.includes(i)
          }, _createSlots({ _: 2 }, [
            (Object.keys(_ctx.customColumnSlots).includes(k))
              ? {
                  name: "default",
                  fn: _withCtx((scope) => [
                    _renderSlot(_ctx.$slots, _ctx.customColumnSlots[k], {
                      val: scope.row[k],
                      row: scope.row
                    }, undefined, true)
                  ]),
                  key: "0"
                }
              : undefined
          ]), 1032, ["prop", "label", "align", "min-width", "formatter", "sortable"]))
        }), 128)),
        (_ctx.editAction != null || _ctx.deleteAction != null || _ctx.showOperation)
          ? (_openBlock(), _createBlock(_component_el_table_column, {
              key: 2,
              fixed: "right",
              label: "操作",
              width: _ctx.operationWidth,
              align: "center"
            }, {
              default: _withCtx((tab) => [
                _renderSlot(_ctx.$slots, "otherAction", {
                  val: tab.row
                }, undefined, true),
                (_ctx.editAction != null)
                  ? (_openBlock(), _createBlock(_component_el_button, {
                      key: 0,
                      type: "primary",
                      link: "",
                      onClick: ($event: any) => (_ctx.editAction ? _ctx.editAction(tab.row, tab.$index) : null)
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_icon, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_Edit)
                          ]),
                          _: 1
                        }),
                        _createTextVNode("编辑")
                      ]),
                      _: 2
                    }, 1032, ["onClick"]))
                  : _createCommentVNode("", true),
                (_ctx.deleteAction != null)
                  ? (_openBlock(), _createBlock(_component_el_button, {
                      key: 1,
                      link: "",
                      type: "danger",
                      onClick: ($event: any) => (_ctx.deleteAction ? _ctx.alertDeleteAction(tab.row, tab.$index) : null)
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_icon, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_Delete)
                          ]),
                          _: 1
                        }),
                        _createTextVNode("删除")
                      ]),
                      _: 2
                    }, 1032, ["onClick"]))
                  : _createCommentVNode("", true),
                _renderSlot(_ctx.$slots, "moreAction", {
                  val: tab.row
                }, undefined, true)
              ]),
              _: 3
            }, 8, ["width"]))
          : _createCommentVNode("", true)
      ]),
      _: 3
    }, 8, ["height", "cell-style", "row-style", "header-cell-style", "data", "border", "row-key", "scrollbar-always-on", "highlight-current-row", "onCurrentChange", "onSelectionChange", "onRowClick"])), [
      [_directive_loading, _ctx.loading]
    ]),
    (_ctx.showPagination)
      ? (_openBlock(), _createBlock(_component_el_pagination, {
          key: 0,
          style: {"margin-top":"10px"},
          "current-page": _ctx.page,
          "onUpdate:currentPage": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.page) = $event)),
          "page-size": _ctx.size,
          "onUpdate:pageSize": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.size) = $event)),
          total: _ctx.total,
          onChange: _ctx.pageAndSizeChange
        }, null, 8, ["current-page", "page-size", "total", "onChange"]))
      : _createCommentVNode("", true)
  ]))
}