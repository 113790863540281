
import { resetForm } from "@/utils/base";
import { ElForm } from "element-plus";
import { ref } from "vue";
import { defineComponent } from "vue-demi";

export default defineComponent({

  props:{
    formData:{
        type:Object
    },
    handleQuery:{
        type:Function
    },
    hideReset:{
      type:Boolean,
      default: () => {
        return false;
      },
    }
},
setup(prop){
    const queryForm = ref<typeof ElForm>();

    return{
        resetQuery,
        queryForm,

    }
    function resetQuery(){
       
        resetForm(queryForm.value);
      if(prop.handleQuery){
          prop.handleQuery();
      }
    }
}
})
