

import { defineComponent } from "vue";

export default defineComponent({
  props:{
    title:{
      type:String,
      default:()=>{
        return '编辑';
      }
    },
    width:{
      type: String || Number,
      default:()=>{
        return '50%'
      }
    },

    show:{
      type:Boolean,
      default:()=>{
        return false;
      }
    },
    destroyOnClose:{
      type:Boolean,
      default:()=>{
        return false;
      }
    },
    confirm:{
      type:Function,
    },
    confirmBtnName:{
      type:String,
      default:()=>{
        return '保 存';
      }
    },
    hideFooter:{
      type:Boolean,
      default:()=>{
        return false;
      }
    }
    // cancel:{
    //     type:Function
    // }
  }
})

