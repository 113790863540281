import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-204a7485"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "footer"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_check = _resolveComponent("check")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_close = _resolveComponent("close")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_el_dialog, {
      "model-value": _ctx.show,
      title: _ctx.title,
      onClosed: _cache[1] || (_cache[1] = ($event: any) => {_ctx.$emit('update:show',false);_ctx.$emit('close')}),
      width: _ctx.width,
      "destroy-on-close": _ctx.destroyOnClose,
      "align-center": ""
    }, {
      default: _withCtx(() => [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true),
        (!_ctx.hideFooter)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _renderSlot(_ctx.$slots, "otherBtn", {}, undefined, true),
              (_ctx.confirm!=null)
                ? (_openBlock(), _createBlock(_component_el_button, {
                    key: 0,
                    type: "primary",
                    onClick: _ctx.confirm
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_icon, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_check)
                        ]),
                        _: 1
                      }),
                      _createTextVNode(_toDisplayString(_ctx.confirmBtnName), 1)
                    ]),
                    _: 1
                  }, 8, ["onClick"]))
                : _createCommentVNode("", true),
              _createVNode(_component_el_button, {
                onClick: _cache[0] || (_cache[0] = ($event: any) => {_ctx.$emit('update:show',false);_ctx.$emit('closed')})
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_icon, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_close)
                    ]),
                    _: 1
                  }),
                  _createTextVNode("取 消")
                ]),
                _: 1
              })
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 3
    }, 8, ["model-value", "title", "width", "destroy-on-close"])
  ]))
}