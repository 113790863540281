// 保留小数的公共方法
export function toFloat(e:string, num: number) {
    const er = parseFloat(e) || 0;
    num = num || 2;
    
    if (
        er.toString().split(".")[1] != undefined &&
        er.toString().split(".")[1].length > num
    ) {
        return (Math.floor(er * Math.pow(10, num)) / Math.pow(10, num)).toString();
    } else {
        return addZero(e,num);
    }
}

// 保留小数，四舍五入
export function toDecimal(x:string, num:number) {
     let val = "";
    if (!isNaN(parseFloat(x))) {

        val = parseFloat(x).toFixed(num);
    }
    return val;
}

function addZero(s:string,num:number){
    let len = s.split('.')[1].length;
    if(len<num){
        for(;len<num;len++){
            s+='0';
        }
    }
    return s;
}
// 表单重置
export function resetForm(ref: any) {

  console.log(ref);
    if (ref) {
        
        ref.resetFields();
    }
}

// 对象去空
export function deleteEmpty(obj:Record<string,any>) {
    for (const key in obj) {
        if (obj[key] === '') {
            delete obj[key]
        }
    }
    return obj
}
/**
 * 构造树型结构数据
 * @param {*} data 数据源
 * @param {*} id id字段 默认 'id'
 * @param {*} parentId 父节点字段 默认 'parentId'
 * @param {*} children 孩子节点字段 默认 'children'
 */
export function handleTree(data:Record<string,any>[], id:string, parentId:string, children:string) {
    const config = {
      id: id || 'id',
      parentId: parentId || 'parentId',
      childrenList: children || 'children'
    };
  
    const childrenListMap:Record<string,any> = {};
    const nodeIds:Record<string,any> = {};
    const tree = [];
  
    for (const d of data) {
        const parentId = d[config.parentId];
      if (childrenListMap[parentId] == null) {
        childrenListMap[parentId] = [];
      }
      nodeIds[d[config.id]] = d;
      childrenListMap[parentId].push(d);
    }
  
    for (const d of data) {
        const parentId = d[config.parentId];
      if (nodeIds[parentId] == null) {
        tree.push(d);
      }
    }
  
    for (const t of tree) {
      adaptToChildrenList(t);
    }
  
    function adaptToChildrenList(o:any) {
      if (childrenListMap[o[config.id]] !== null) {
        o[config.childrenList] = childrenListMap[o[config.id]];
      }
      if (o[config.childrenList]) {
        for (const c of o[config.childrenList]) {
          adaptToChildrenList(c);
        }
      }
    }
    return tree;
  }